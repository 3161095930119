  <template>
  <div class="chip-list" :class="{ 'chip-list--grow': grow, 'chip-list--scrollable': scrollable }">
    <slot>
      <Chip
        :icon="small"
        v-for="item in items"
        @click="onClick(item)"
        :selected="selected === id(item)"
        :title="item?.name ?? item?.title ?? item"
        :not-clickable="notClickable"
      >
        <span>{{ item?.name ?? item?.title ?? item }}</span>
        <IconX v-if="removable" class="icon" />
      </Chip>
    </slot>
  </div>
</template>

<script setup>
import { IconX } from '@tabler/icons-vue'

const props = defineProps({
  items: Array,
  removable: Boolean,
  select: Boolean,
  modelValue: [Number, String],
  small: Boolean,
  grow: Boolean,
  scrollable: Boolean,
  canDeselect: {
    type: Boolean,
    default: true
  },
  notClickable: Boolean
})
const emit = defineEmits(['remove', 'update:modelValue'])

const selected = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

function id (item) {
  return item?.id ?? item
}

function onClick (item) {
  if (props.removable) return emit('remove', id(item))
  if (props.select) return selected.value = (!props.canDeselect || selected.value !== id(item) ? id(item) : null)
}
</script>

<style scoped lang="scss">
.chip-list {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  &--grow > :deep(*) {
    flex-grow: 1;
  }
  &--scrollable {
    flex-wrap: nowrap;
    overflow-x: auto;
    &::-webkit-scrollbar { display: none; }
    & > * { flex-shrink: 0; }
  }
}
</style>
